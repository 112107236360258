import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/img/plus.png'


const _hoisted_1 = { class: "payment" }
const _hoisted_2 = {
  key: 0,
  class: "payment-top"
}
const _hoisted_3 = { class: "payment-title" }
const _hoisted_4 = { class: "pay-way" }
const _hoisted_5 = { class: "payment-bottom" }
const _hoisted_6 = { class: "tips" }
const _hoisted_7 = { class: "time-tips" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "time" }

import { useRouter, useRoute } from 'vue-router';
import { onMounted, reactive } from 'vue';
import dataCenter from '@/dataCenter';
import { fetchTransactionServeGoods } from '@/api/fetcher';
import { lang } from '@/lang';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  type: {
    type: Number,
    default: 1,
  },
  create: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const data = reactive({
  payWay: '',
  payDesc: '',
  payWayName: '',
});
const router = useRouter();
const route = useRoute();
const onPayWay = () => {
  if (props.type == 2 && !props.disabled) {
    router.push({
      path: '/transactionServeSelectPay',
      query: {
        id: route.query.id || 0,
      },
    });
  }
};

// 获取详情
const getTransactionDes = async () => {
  const data = await fetchTransactionServeGoods({
    id: route.query.id,
  });
  if (data) {
    const existingId = dataCenter.payStatus.findDataPayStatus(route.query.id);
    if (existingId) {
      existingId.payDesc = data?.payDesc;
    }
  }
};
onMounted(async () => {
  const foundItem = dataCenter.payStatus.data.find(
    item => item.id == route.query.id
  );
  if (!foundItem?.payDesc && foundItem?.payDesc !== 0) {
    await getTransactionDes();
  }
  if (foundItem) {
    data.payWay = foundItem?.payWay;
    data.payDesc = foundItem.payDesc;
    data.payWayName = foundItem.payWayName;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.create)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(lang).v.paymentMethod), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(data.payWayName), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createElementVNode("div", {
        class: "text-tips",
        onClick: onPayWay
      }, [
        _createElementVNode("span", null, _toDisplayString(data?.payDesc), 1)
      ], 512), [
        [_vShow, data.payWay && !__props.create]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "change-way",
        onClick: onPayWay
      }, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(lang).v.addPaymentInformation), 1),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          class: "change-img"
        }, null, -1))
      ], 512), [
        [_vShow, !data.payWay && !__props.create]
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(lang).v.paymentDeadline), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(dataCenter).payStatus.timeLimitMinutes) + _toDisplayString(_unref(lang).v.minute), 1)
      ])
    ])
  ]))
}
}

})